import { Button, CountryMobileNumber, Input, Loader } from '@storybook';
import { BodyWrapper, LabelElement } from 'components';
import { useNotification, useUrl } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IPhoneNumber, LoginPhoneNumberState } from 'states';
import { OtpVerify } from 'views';
import {
	PRIVATE_RELAY,
	QR_CONTINUE_LABEL,
	emailErrors,
	headers,
} from 'views/constants';
import { loginToasterMessages, useOtpVerify } from 'views/otp-verify/stores';
import './basic-information.scss';
import {
	AppleUserDetailsState,
	BasicInfoDataState,
	BasicInformationState,
	SessionExistState,
	useBasicInformation,
} from './store';
import { validateName } from 'utils';

const dollarsInvested = { key: '0' };

const initialErrorValue = {
	firstName: false,
	lastName: false,
	phone: false,
	countryCode: false,
	email: false,
};

interface IExtractedData {
	firstName: string | null;
	lastName: string | null;
	phone: string | null;
	countryCode: string | null;
	email: string | null;
}

const showOtpCountryList = ['+91', '+1'];

export const BasicInformation = () => {
	// Global state
	const [loginPhoneNumber, setLoginPhoneNumber] = useRecoilState(
		LoginPhoneNumberState
	);
	const isSessionExist = useRecoilValue(SessionExistState);

	const { isLoaded, continueInvite, abortInvite, inviteUser } =
		useBasicInformation();

	const appleUserDetails = useRecoilValue(AppleUserDetailsState);

	const [basicInfoData, setBasicInfoData] = useRecoilState(BasicInfoDataState);

	const [isModalBtnLoaded, setIsModalBtnLoaded] = useState('');
	const [payload, setPayload] = useRecoilState(BasicInformationState);
	const [isError, setIsError] = useState(initialErrorValue);
	const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [extractedData, setExtractedData] = useState<IExtractedData>({
		firstName: null,
		lastName: null,
		phone: null,
		countryCode: null,
		email: null,
	});
	const [isOtpScreen, setIsOtpScreen] = useState<boolean>(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState(
		emailErrors.REGULAR_EMAIL_ERROR
	);
	const otpInputRef: React.RefObject<HTMLInputElement> = useRef(null);
	const firstNameInputRef: React.RefObject<HTMLInputElement> = useRef(null);

	const { code } = useUrl();
	const { getOtp } = useOtpVerify();
	const { errorNotification } = useNotification();

	//Anuj Change : Get pre-fill data through query param for liquidity
	const extractDataFromURL = useCallback(() => {
		const currentURL = window.location.href;
		const phoneRegex = /phone=([0-9]+)/;
		const countryCodeRegex = /countryCode=([+0-9]+)/;
		const emailRegex = /email=([^&]+)/;
		const firstNameRegex = /firstName=([a-z]+)/;
		const lastNameRegex = /lastName=([a-z]+)/;

		const phoneMatch = currentURL.match(phoneRegex);
		const countryCodeMatch = currentURL.match(countryCodeRegex);
		const emailMatch = currentURL.match(emailRegex);
		const firstNameMatch = currentURL.match(firstNameRegex);
		const lastNameMatch = currentURL.match(lastNameRegex);
		const capitalizeString = (str: string) => {
			return str.charAt(0).toUpperCase() + str.slice(1);
		};

		const newData: IExtractedData = { ...extractedData };

		if (firstNameMatch) {
			newData.firstName = capitalizeString(firstNameMatch[1] ?? '');
		}

		if (lastNameMatch) {
			newData.lastName = capitalizeString(lastNameMatch[1] ?? '');
		}

		if (phoneMatch) {
			newData.phone = phoneMatch[1] ?? '';
		}

		if (countryCodeMatch) {
			newData.countryCode = countryCodeMatch[1] ?? '';
		}

		if (emailMatch?.length && emailMatch.length > 0) {
			newData.email = decodeURIComponent(emailMatch[1] ?? '');
		}

		setExtractedData(newData);
	}, [extractedData]);

	const moveOtpScreen = useCallback(() => {
		setIsOtpScreen(prev => !prev);
	}, []);

	const onHandlePhoneChange = useCallback(
		({ countryCode, phone }: IPhoneNumber) => {
			setIsError(prevState => ({
				...prevState,
				phone: false,
			}));
			setLoginPhoneNumber((prevState: IPhoneNumber) => {
				const newState = {
					...prevState,
					countryCode: countryCode,
					phone: phone.replace(/\D/g, ''),
				};
				return newState;
			});
		},
		[setLoginPhoneNumber]
	);

	const onHandleChangeCountry = useCallback(
		(countryCode: string | number) => {
			setLoginPhoneNumber((prevState: IPhoneNumber) => {
				const newState = {
					...prevState,
					countryCode: countryCode,
				};
				return newState;
			});
		},
		[setLoginPhoneNumber]
	);

	useEffect(() => {
		extractDataFromURL();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isPrivateRelay = useCallback((value: string) => {
		return value?.toLowerCase().includes(PRIVATE_RELAY);
	}, []);

	useEffect(() => {
		setBasicInfoData(prev => {
			const {
				firstName: extractedFirstName,
				lastName: extractedLastName,
				email: extractedEmail,
			} = extractedData;
			const {
				firstName: appleFirstName,
				lastName: appleLastName,
				email: appleEmail,
			} = appleUserDetails;

			return {
				...prev,
				firstName: extractedFirstName || appleFirstName || prev.firstName || '',
				lastName: extractedLastName || appleLastName || prev.lastName || '',
				email:
					[extractedEmail, appleEmail, prev.email].find(
						email => email && !isPrivateRelay(email)
					) || '',
			};
		});

		setLoginPhoneNumber((prevState: any) => {
			const newState = {
				...prevState,
				countryCode: extractedData.countryCode
					? extractedData.countryCode
					: appleUserDetails.countryCode ?? prevState.countryCode ?? '',
				phone: extractedData.phone
					? extractedData.phone
					: appleUserDetails.phone ?? prevState.phone ?? '',
			};
			return newState;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appleUserDetails, extractedData]);

	const handleValidation = (isValid: boolean) => {
		setIsPhoneValid(isValid);
	};
	const handleSubmit = useCallback(async () => {
		if (!basicInfoData?.firstName?.trim() || !basicInfoData?.lastName?.trim()) {
			errorNotification(loginToasterMessages.InvalidInput);
			return;
		}
		setIsLoading(true);

		if (isLoaded) {
			setBasicInfoData({ ...basicInfoData, ...loginPhoneNumber });
			const users = {
				headers: headers,
				rows: [
					Object.values({
						...basicInfoData,
						...loginPhoneNumber,
						...dollarsInvested,
					}),
				],
			};
			setPayload({
				users,
				qrId: code,
			});

			const checkedValidCountryOtp =
				showOtpCountryList.includes(appleUserDetails.countryCode) ||
				showOtpCountryList.includes(loginPhoneNumber.countryCode.toString());
			// Awadhesh: hide otp page for other countries
			if (appleUserDetails.phone?.length || !checkedValidCountryOtp) {
				await inviteUser({
					users,
					qrId: code,
				});
			} else {
				otpInputRef.current?.focus();
				const resp = await getOtp();
				if (resp?.statusCode === 200) {
					moveOtpScreen();
				}
				setIsLoading(false);
			}
		}
	}, [
		basicInfoData,
		isLoaded,
		errorNotification,
		setBasicInfoData,
		loginPhoneNumber,
		setPayload,
		code,
		appleUserDetails.countryCode,
		appleUserDetails.phone?.length,
		inviteUser,
		getOtp,
		moveOtpScreen,
	]);

	const handleContinue = useCallback(async () => {
		setIsModalBtnLoaded(QR_CONTINUE_LABEL.old);
		await continueInvite();
	}, [continueInvite]);

	const handleNew = useCallback(async () => {
		setIsModalBtnLoaded(QR_CONTINUE_LABEL.new);
		await abortInvite(payload);
	}, [abortInvite, payload]);

	const validateEmail = useCallback(
		(emailString: string) => {
			const trimmedEmail = emailString.trim();
			const emailRegex =
				// eslint-disable-next-line no-useless-escape
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			if (isPrivateRelay(trimmedEmail)) {
				return true;
			} else if (emailRegex.test(trimmedEmail) || trimmedEmail.length === 0) {
				return false;
			} else {
				return true;
			}
		},
		[isPrivateRelay]
	);

	const nextButtonLabel = useMemo(() => {
		if (isLoaded) {
			return 'Next';
		}
		return !isModalBtnLoaded.length ? (
			<Loader type="circle" dimension={24} className="loader-white" />
		) : (
			'Next'
		);
	}, [isLoaded, isModalBtnLoaded.length]);

	const isDisable = useMemo(() => {
		const validBasicInfo =
			Object.values({ ...basicInfoData, ...loginPhoneNumber })?.every(
				(value: any) => value?.trim().length
			) &&
			loginPhoneNumber.phone.length > 2 &&
			!validateEmail(basicInfoData.email) &&
			validateName(basicInfoData.firstName) &&
			validateName(basicInfoData.lastName);
		return validBasicInfo;
	}, [basicInfoData, loginPhoneNumber, validateEmail]);

	const handleError = useCallback(
		(key: string) => {
			switch (key) {
				case 'firstName':
				case 'lastName':
					setBasicInfoData(prev => ({ ...prev, [key]: prev[key]?.trim() }));
					setIsError(prevState => ({
						...prevState,
						[key]: !validateName(basicInfoData[key]?.trim()),
					}));
					break;
				case 'email':
					setIsError(prevState => ({
						...prevState,
						[key]: validateEmail(basicInfoData.email),
					}));
					break;
				case 'phone':
					setIsError(prevState => ({
						...prevState,
						[key]: loginPhoneNumber.phone.length < 6,
					}));
					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[basicInfoData, loginPhoneNumber.phone]
	);

	const handleInfoChange = useCallback(
		(value: string, key: string) => {
			setIsError(prevState => ({
				...prevState,
				[key]: false,
			}));
			if (isPrivateRelay(value)) {
				setIsError(prevState => ({
					...prevState,
					[key]: true,
				}));
				setEmailErrorMessage(emailErrors.PRIVATE_RELAY_ERROR);
			}
			setBasicInfoData(prevState => ({
				...prevState,
				[key]: value,
			}));
		},
		[isPrivateRelay, setBasicInfoData]
	);

	const bodyElement = useMemo(
		() => (
			<div className="basic_info_container">
				<div className="basic_info_container__title">
					<h4>Please provide these basic information</h4>
				</div>

				<div className="input_div_container">
					<Input
						inputType="text"
						placeholder="First name"
						value={basicInfoData?.firstName}
						label="First Name"
						inputRef={firstNameInputRef}
						isRequired={true}
						handleChange={e => handleInfoChange(e.target.value, 'firstName')}
						handleBlur={() => handleError('firstName')}
						isError={isError.firstName}
						errorMessage="First name is invalid"
					/>
					<Input
						inputType="text"
						placeholder="Last name"
						value={basicInfoData?.lastName}
						label="Last Name"
						isRequired={true}
						handleChange={e => handleInfoChange(e.target.value, 'lastName')}
						handleBlur={() => handleError('lastName')}
						isError={isError.lastName}
						errorMessage="Last name is invalid"
					/>
				</div>
				<div className="basic_info_input">
					<CountryMobileNumber
						handleChange={onHandlePhoneChange}
						defaultCountryCode={loginPhoneNumber.countryCode}
						defaultNumber={loginPhoneNumber.phone}
						handleChangeCountry={onHandleChangeCountry}
						phoneNumberDisabled={appleUserDetails.phone || extractedData.phone}
						isError={isError.phone}
						handleBlur={() => handleError('phone')}
						errorMessage="Mobile number is invalid"
						isRequired
						handleValidation={handleValidation}
					/>
				</div>

				<div className="basic_info_input basic_info_input--email basic_info__margin">
					<Input
						inputType="text"
						placeholder="Email address"
						autoComplete="on"
						isError={isError.email}
						errorMessage={emailErrorMessage}
						value={basicInfoData?.email}
						disabled={
							(appleUserDetails.email &&
								!/@privaterelay/.test(appleUserDetails.email)) ||
							extractedData.email
						}
						handleChange={e => handleInfoChange(e.target.value, 'email')}
						label="Email"
						isRequired={true}
						handleBlur={() => handleError('email')}
					/>
				</div>
				<div className="basic_info_button">
					<Button
						type={'button__filled--primary button__large button__block'}
						handleClick={handleSubmit}
						label={
							isLoading ? (
								<Loader type="loader" dimension={20} />
							) : (
								nextButtonLabel
							)
						}
						disabled={!isPhoneValid || !isDisable || isLoading}
					/>
				</div>
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			appleUserDetails.firstName,
			appleUserDetails.lastName,
			appleUserDetails.email,
			loginPhoneNumber.countryCode,
			loginPhoneNumber.phone,
			basicInfoData,
			onHandlePhoneChange,
			onHandleChangeCountry,
			isError,
			handleError,
			handleSubmit,
			nextButtonLabel,
			isDisable,
			isLoading,
			emailErrorMessage,
		]
	);

	const existBodyElementScreen = useMemo(() => {
		return (
			<div
				className={`basic_info_container ${
					!(
						(isModalBtnLoaded === QR_CONTINUE_LABEL.old ||
							isModalBtnLoaded === QR_CONTINUE_LABEL.new) &&
						isLoaded
					)
						? ''
						: 'basic_info_container__disabled'
				}`}
			>
				<div>
					<h4>{QR_CONTINUE_LABEL.header} </h4>
					<p className="basic_info_para2">{QR_CONTINUE_LABEL.subHeader}</p>
				</div>
				<div
					className="input_div_container"
					onKeyDown={() => ({})}
					onClick={handleContinue}
				>
					<div className="session-wrapper">
						<div className="session-wrapper__session-icon">
							<i className="session-wrapper__left-icon ri-contacts-fill" />
						</div>
						<div className="session-wrapper__session_text">
							<p className="session_text__1">{QR_CONTINUE_LABEL.old}</p>
							<p className="session_text__2">
								{QR_CONTINUE_LABEL.old_description}
							</p>
						</div>
						<div className="session-wrapper__right-icon-wrapper">
							{!(isModalBtnLoaded === QR_CONTINUE_LABEL.old && isLoaded) ? (
								<i className="session-wrapper__right-icon ri-arrow-right-s-line" />
							) : (
								<Loader type="circle" dimension={16} className="loader-white" />
							)}
						</div>
					</div>
				</div>
				<div
					className="input_div_container"
					onKeyDown={() => ({})}
					onClick={handleNew}
				>
					<div className="session-wrapper">
						<div className="session-wrapper__session-icon">
							<i className="session-wrapper__left-icon ri-user-add-fill" />
						</div>
						<div className="session-wrapper__session_text">
							<p className="session_text__1">{QR_CONTINUE_LABEL.new}</p>
							<p className="session_text__2">
								{QR_CONTINUE_LABEL.new_description}
							</p>
						</div>
						<div className="session-wrapper__right-icon-wrapper">
							{!(isModalBtnLoaded === QR_CONTINUE_LABEL.new && isLoaded) ? (
								<i className="session-wrapper__right-icon ri-arrow-right-s-line" />
							) : (
								<Loader type="circle" dimension={16} className="loader-white" />
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}, [handleContinue, isModalBtnLoaded, isLoaded, handleNew]);

	const style = useMemo(() => {
		if (!isOtpScreen) {
			return {
				opacity: 0,
				height: 1,
				width: 1,
				overflow: 'hidden',
			};
		}
		return {};
	}, [isOtpScreen]);

	// Shahbaaz: If user want to continue existing flow
	if (isSessionExist) {
		return (
			<div className="terms_header_container">
				<BodyWrapper
					label={
						<div className="terms_header">
							<LabelElement text={'Basic Information'} />
						</div>
					}
					bodyElement={existBodyElementScreen}
				/>
			</div>
		);
	}

	return (
		<>
			{!isOtpScreen && (
				<BodyWrapper
					label={
						<div className="terms_header">
							<LabelElement text={'Basic Information'} />
						</div>
					}
					bodyElement={bodyElement}
				/>
			)}

			<div style={style}>
				<OtpVerify
					isOtpScreen={isOtpScreen}
					otpInputRef={otpInputRef}
					moveOtpScreen={moveOtpScreen}
				/>
			</div>
		</>
	);
};
