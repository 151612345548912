export async function retryOperation(
	operation: () => Promise<any>,
	maxRetries: number,
	delayMs: number = 0
): Promise<any> {
	let attempt = 0;
	while (attempt < maxRetries) {
		const result = await operation();
		try {
			if (result?.statusCode === 200) return result;
			// Check if the 'result.message' contains both the words "hold" and "investment"
			if (/^(?=.*\bhold\b)(?=.*\binvestment\b).+$/.test(result?.message)) {
				// If both words are found, return the 'result' object
				return result;
			}
			if (attempt >= maxRetries - 1) {
				return result;
			}
			attempt++;
			//paras : checking purpose we added console
			console.log('attemp', attempt); //eslint-disable-line

			if (delayMs > 0) {
				// Wait before retrying
				await new Promise(resolve => setTimeout(resolve, delayMs));
			}
		} catch {
			return result;
		}
	}
}
